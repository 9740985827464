import React from "react";
import PropTypes from "prop-types";

import Layout from "../../components/layout/Layout";
import SEO from "../../components/seo/Seo";
import Bigcommerce from "../../components/integration/bigcommerce/Bigcommerce";
import i18nContext from "../../components/i18n/i18n.context";
import Page from "../../components/page/Page";

function BigcommercePage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["gatsby", "react"]}
          location={location}
          title="BusinessLocator dans BigCommerce"
        />
        <Page>
          <Bigcommerce />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

BigcommercePage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default BigcommercePage;
